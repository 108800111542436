<template>
  <div class="liability-footer">
    <div class="liability-footer__limit-control">
      <div class="liability-footer__label">
        Showing
      </div>
      <div
        ref="limitSelectElement"
        class="liability-footer__limit-select"
      >
        <div
          class="liability-footer__limit-field"
          @click="toggleLimitMenu"
        >
          {{ parameters.limit }}
          <Icon name="chevron-selector" />
        </div>
        <div
          v-show="isLimitMenuOpen"
          class="liability-footer__limit-menu"
        >
          <div
            v-for="option in validLimits"
            :key="option"
            class="liability-footer__limit-item"
            @click="setLimit(option)"
          >
            {{ option }}
          </div>
        </div>
      </div>
      <div class="liability-footer__label">
        results per page
      </div>
      <div class="liability-footer__label liability-footer__label--is-muted">
        ({{ rowCount }} in total)
      </div>
    </div>
    <ul
      v-show="pageNumberRange.length"
      class="liability-footer__pagination"
    >
      <li class="liability-footer__pagination-item">
        <div
          class="liability-footer__pagination-button liability-footer__pagination-button--is-nav"
          @click="setPage(currentPage - 1)"
          :disabled="isFirstPage"
        >
          <Icon name="chevron-left" />
        </div>
      </li>

      <li
        v-show="!isFirstPageInRange"
        class="liability-footer__pagination-item"
      >
        <div
          class="liability-footer__pagination-button"
          @click="setPage(1)"
          :disabled="isFirstPage"
        >
          1
        </div>
      </li>

      <li
        v-show="showFirstEllipsis"
        class="liability-footer__pagination-item"
      >
        <span class="liability-footer__pagination-text">
          &hellip;
        </span>
      </li>

      <li
        class="liability-footer__pagination-item"
        v-for="pageNumber in pageNumberRange"
        :key="pageNumber"
      >
        <div
          :class="[
            'liability-footer__pagination-button',
            { 'liability-footer__pagination-button--is-toggled': currentPage === pageNumber },
          ]"
          @click="setPage(pageNumber)"
        >
          <span class="sr-only">Go to page</span>
          {{ pageNumber }}
        </div>
      </li>

      <li
        v-show="showLastEllipsis"
        class="liability-footer__pagination-item"
      >
        <span class="liability-footer__pagination-text">
          &hellip;
        </span>
      </li>

      <li
        v-show="!isLastPageInRange"
        class="liability-footer__pagination-item"
      >
        <div
          class="liability-footer__pagination-button"
          @click="setPage(lastPage)"
          :disabled="isLastPage"
        >
          {{ lastPage }}
        </div>
      </li>

      <li class="liability-footer__pagination-item">
        <div
          class="liability-footer__pagination-button liability-footer__pagination-button--is-nav"
          @click="setPage(currentPage + 1)"
          :disabled="isLastPage"
        >
          <Icon name="chevron-right" />
        </div>
      </li>
    </ul>
    <!-- <div class="liability-footer__page-control">
      <div
        v-for="page in pageRange"
        :key="page"
        :class="[
          'liability-footer__page-item',
          { 'liability-footer__page-item--is-active': currentPage === page },
        ]"
        @click="setPage(page)"
      >
        {{ page }}
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  ceil,
  includes,
  max,
  min,
  range,
} from 'lodash';
import { onClickOutside } from '@vueuse/core';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import Icon from '@/components/common/Icon';
import { useQueryParameters } from '@/composables';
import { getQueryModel } from './liability-table-helper';

const queryModel = getQueryModel();

export default {
  components: {
    Icon,
  },
  setup() {
    const store = useStore();

    const rowCount = computed(() => store.getters['betTicker/liabilityEventsRowCount']);
    const { state, parameters, setParameters } = useQueryParameters(queryModel);

    const limitSelectElement = ref(null);
    const isLimitMenuOpen = ref(false);
    const toggleLimitMenu = () => {
      isLimitMenuOpen.value = !isLimitMenuOpen.value;
    };
    const closeLimitMenu = () => {
      isLimitMenuOpen.value = false;
    };
    const setLimit = (limit) => {
      setParameters({
        ...state.value,
        page: 1,
        limit,
      });
      closeLimitMenu();
    };

    const currentPage = computed(() => state.value.page);
    const lastPage = computed(() => ceil(rowCount.value / state.value.limit));
    const pageNumberRange = computed(() => {
      if (currentPage.value === 1) {
        return range(1, min([lastPage.value, currentPage.value + 2]) + 1);
      }

      if (currentPage.value === lastPage.value) {
        return range(max([1, lastPage.value - 2]), lastPage.value + 1);
      }

      return range(currentPage.value - 1, currentPage.value + 1 + 1);
    });
    const isFirstPageInRange = computed(() => includes(pageNumberRange.value, 1));
    const showFirstEllipsis = computed(() => !isFirstPageInRange.value && lastPage.value !== 4 && currentPage.value - 2 > 1);
    const isLastPageInRange = computed(() => includes(pageNumberRange.value, lastPage.value));
    const showLastEllipsis = computed(() => !isLastPageInRange.value && lastPage.value !== 4 && currentPage.value + 2 < lastPage.value);
    const setPage = (page) => {
      if (page < 1 || page > lastPage.value) return;
      setParameters({
        ...state.value,
        page,
      });
    };

    onClickOutside(limitSelectElement, closeLimitMenu);

    return {
      validLimits: [10, 20, 50, 100],
      rowCount,
      state,
      parameters,
      setParameters,
      limitSelectElement,
      isLimitMenuOpen,
      toggleLimitMenu,
      closeLimitMenu,
      setLimit,
      currentPage,
      lastPage,
      pageNumberRange,
      setPage,
      setPreviousPage: () => {
        setPage(currentPage.value - 1);
      },
      setNextPage: () => {
        setPage(currentPage.value + 1);
      },
      isFirstPage: computed(() => currentPage.value === 1),
      isFirstPageInRange,
      showFirstEllipsis,
      isLastPage: computed(() => currentPage.value === lastPage.value),
      isLastPageInRange,
      showLastEllipsis,
    };
  },
};
</script>

<style lang="scss">
.liability-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  width: 100%;
  height: 76px;
  min-height: 76px;
  max-height: 76px;
  padding: 16px;
  margin: 0 -16px;
  position: fixed;
  bottom: 0;

  &__limit-control {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 100%;
  }

  &__pagination-control {
    height: 100%;
  }

  &__label {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #191414;

    &--is-muted {
      color: #6D6D6D;
    }
  }

  &__limit-select {
    position: relative;
  }

  &__limit-field {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    height: 44px;
    min-height: 44px;
    max-height: 44px;
    padding: 0 8px;
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    user-select: none;
    cursor: pointer;
  }

  &__limit-menu {
    position: absolute;
    left: 0;
    bottom: 100%;
    min-width: 100%;
    min-height: 32px;
    position: absolute;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #F0F0F0;
    box-shadow: 0px 2px 4px 0px rgba(25, 20, 20, 0.0784313725);
    padding: 2px 0;
    z-index: 999;
    overflow: hidden;
  }

  &__limit-item {
    display: flex;
    align-items: center;
    min-height: 32px;
    max-height: 32px;
    height: 32px;
    padding: 0 8px;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $paginationButtonBackgroundColor;
      border-radius: $paginationButtonBorderRadius;
      width: $paginationButtonWidth;
      height: $paginationButtonHeight;
      margin: $paginationButtonMargin;
      cursor: pointer;
      user-select: none;

      .icon {
        stroke: $paginationButtonIconStroke;
        width: $paginationButtonIconSize;
        height: $paginationButtonIconSize;
      }

      &:hover,
      &.liability-footer__pagination-button--is-nav {
        border: $paginationNavigationButtonBorder;
      }

      &.liability-footer__pagination-button--is-toggled {
        background-color: $paginationToggledButtonBackgroundColor;
        border-color: $paginationToggledButtonBorderColor;
        color: $paginationToggledButtonTextColor;
      }

      &.liability-footer__pagination-button--is-nav {
        width: $paginationNavigationButtonWidth;
        height: $paginationNavigationButtonHeight;

        &:hover {
          background-color: $paginationNavigationButtonHoverBackgroundColor;
        }

        &:focus {
          background-color: $paginationNavigationButtonFocusBackgroundColor;
        }
      }
    }
  }
}
</style>
