<template>
  <div class="liability-selection-tabs-wrapper">
    <div
      :class="['liability-selection-tab', {'selected': selectedLiabilityFilter === 'betsCount'}]"
      @click="selectLiabilityFilter('betsCount')"
    >
      Number of bets
    </div>
    <div
      :class="['liability-selection-tab', {'selected': selectedLiabilityFilter === 'volume'}]"
      @click="selectLiabilityFilter('volume')"
    >
      Turnover
    </div>
    <div
      :class="['liability-selection-tab', {'selected': selectedLiabilityFilter === 'pnl'}]"
      @click="selectLiabilityFilter('pnl')"
    >
      Profit/loss
    </div>
  </div>
  <div class="liability-selection-tabs-wrapper">
    <div
      :class="['liability-selection-tab', {'selected': selectedSelectionFilter === 'all'}]"
      @click="selectSelectionsFilter('all')"
    >
      All selections
    </div>
    <div
      :class="['liability-selection-tab dynamic', {'selected': selectedSelectionFilter === selection}]"
      v-for="(selection, index) in marketSelectionsCodes"
      :key="index"
      @click="selectSelectionsFilter(selection)"
    >
      {{ (selection === 'HOME' || selection === 'AWAY') ? setTeamNames(selection) : selection.toLowerCase() }}
    </div>
  </div>
  <Bar
    v-if="markets?.length"
    class="bar-chart"
    id="bar-chart"
    :chart-options="chartOptions"
    :chart-data="chartData"
  />
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { Bar } from 'vue-chartjs';
import {
  Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,
} from 'chart.js';
import {
  includes, toLower, replace,
  split, map, find, filter,
} from 'lodash';
import MarketMapper from '@/services/helpers/market-mapper';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  components: { Bar },
  props: {
    marketIds: {
      type: Array,
      required: true,
    },
    sportLabel: {
      type: String,
      required: true,
    },
    homeTeam: {
      type: Object,
      required: true,
    },
    awayTeam: {
      type: Object,
      required: true,
    },
    isUsaView: {
      type: Boolean,
      required: true,
    },
  },
  emits: {
    selectedMarketIndexChange: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const selectedLiabilityFilter = ref('betsCount');
    const selectedSelectionFilter = ref('all');

    const createLiabilities = (obj) => {
      if (!obj) {
        return {
          betsCount: 0,
          volume: 0,
          pnl: 0,
        };
      }
      return {
        betsCount: obj.betsCount,
        volume: parseFloat(obj.volume / 100).toFixed(2),
        pnl: parseFloat(obj.pnl / 100).toFixed(2),
      };
    };

    const setTeamNames = (selectionLabel) => {
      let replacedLabel = toLower(selectionLabel);
      if (includes(replacedLabel, 'home') && replacedLabel !== 'home run') {
        replacedLabel = replace(replacedLabel, /home/g, props.homeTeam?.name);
      }
      if (includes(replacedLabel, 'away')) {
        replacedLabel = replace(replacedLabel, /away/g, props.awayTeam?.name);
      }
      if (includes(replacedLabel, '/')) {
        const labelSplited = split(replacedLabel, '/');
        replacedLabel = {
          twoPartLabel: true,
          leftSide: labelSplited[0],
          rightSide: labelSplited[1],
        };
      }
      return replacedLabel;
    };

    const remapSelectionCode = (selection) => {
      const selectionNameLabel = MarketMapper.selectionName(selection, { sportLabel: props.sportLabel }, props.isUsaView);
      return setTeamNames(selectionNameLabel);
    };

    const markets = computed(() => {
      const storeMarkets = map(props.marketIds, (marketId) => store.getters['betTicker/getLiabilityMarketById'](marketId));
      return map(storeMarkets, (market) => {
        const selections = store.getters['betTicker/getLiabilityMarketSelectionsById'](market.marketId);
        const mappedSelections = map(selections, (selection) => {
          const selectionName = remapSelectionCode(selection);
          const selectionLiabilities = createLiabilities(selection);
          return {
            ...selection,
            selectionName,
            selectionLiabilities,
          };
        });
        return {
          ...market,
          selections: MarketMapper.sortSelections({ selections: mappedSelections, isUSAView: props.isUsaView }),
        };
      });
    });

    const barColors = ['#175FFF', '#FFC531', '#00BC57'];

    const marketSelectionsCodes = computed(() => {
      if (includes(['POINT_HANDICAP', 'GOAL_HANDICAP', 'RUN_HANDICAP'], markets.value?.[0]?.marketCode)) {
        if (props.isUsaView) {
          return ['AWAY', 'HOME'];
        }
        return ['HOME', 'AWAY'];
      }
      if (props.isUsaView) return ['OVER', 'UNDER'];
      return ['UNDER', 'OVER'];
    });

    const chartData = computed(() => ({
      labels: map(markets.value, (market) => market.marketType?.params?.LINE),
      datasets: map(selectedSelectionFilter.value === 'all'
        ? marketSelectionsCodes.value
        : filter(marketSelectionsCodes.value, (selection) => selection === selectedSelectionFilter.value), (selection, index) => {
        const label = (selection === 'HOME' || selection === 'AWAY')
          ? setTeamNames(selection)
          : selection;
        const values = map(markets.value, (market) => {
          const foundSelection = find(market.selections, (sel) => sel.selectionType.selectionCode === selection);
          return foundSelection?.selectionLiabilities?.[selectedLiabilityFilter.value] || 0;
        });
        return {
          label,
          backgroundColor: barColors[index] || '#175FFF',
          data: values,
          barPercentage: 1,
          categoryPercentage: 0.9,
        };
      }),
    }));

    const selectMarketIndex = (index) => {
      emit('selectedMarketIndexChange', index);
    };

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      onClick: (e, market) => {
        if (!market?.length) return;
        selectMarketIndex(market[0]?.index);
      },
      scales: {
        x: {
          grid: {
            drawOnChartArea: false,
          },
        },
        y: {
          grid: {
            borderDash: [3, 3],
          },
        },
      },
      plugins: {
        tooltip: {
          backgroundColor: 'rgba(255, 255, 255, 1)',
          titleColor: '#6D6D6D',
          bodyColor: '#191414',
          footerColor: '#191414',
          borderColor: '#F0F0F0',
          borderWidth: 1,
        },
        legend: {
          position: 'top',
          align: 'end',
          labels: {
            boxWidth: 12,
            boxHeight: 12,
          },
        },
      },
    };

    const selectLiabilityFilter = (selection) => {
      if (selectedLiabilityFilter.value === selection) return;
      selectedLiabilityFilter.value = selection;
    };

    const selectSelectionsFilter = (selection) => {
      if (selectedSelectionFilter.value === selection) return;
      selectedSelectionFilter.value = selection;
    };

    return {
      chartData,
      chartOptions,
      markets,
      selectedLiabilityFilter,
      selectedSelectionFilter,
      marketSelectionsCodes,
      setTeamNames,
      selectLiabilityFilter,
      selectSelectionsFilter,
    };
  },
};
</script>

<style lang="scss">
  .liability-selection-tabs-wrapper {
    width: 100%;
    display: flex;
    box-shadow: inset 0px -2px 0px #F0F0F0;
    height: 32px;

    .liability-selection-tab {
      height: 100%;
      padding: 0 8px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &.selected {
        box-shadow: inset 0px -2px 0px #003C3C;
      }

      &.dynamic {
        text-transform: capitalize;
      }
    }
  }

  .bar-chart {
    width: 100%;
    height: 176px;
  }
</style>
