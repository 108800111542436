<template>
  <div
    class="liability-table-row"
    @click="loadEvent"
  >
    <div class="liability-table-row-column label-type">
      <div
        class="column-label"
        :title="eventName"
      >
        {{ eventName }}
      </div>
      <div :class="['column-label info', {'live': matchState === 'Live'}]">
        {{ event.competition?.competitionName }} •
        <span>{{ matchState }}</span>
      </div>
    </div>
    <div
      class="liability-table-row-column liability-type"
      v-for="(liability, index) in liabilities"
      :key="index"
    >
      <div class="column-liability">
        {{ liability.betsCount }}
      </div>
      <div class="column-liability">
        {{ liability.volume }}
      </div>
      <div class="column-liability">
        <div
          :class="['column-liability-pnl', {'down': parseFloat(liability.pnl) < 0}]"
          v-if="liability.pnl !== '-' && liability.pnl !== '+0' && liability.pnl !== '-0'"
        >
          <Icon :name="parseFloat(liability.pnl) < 0 ? 'trend-down' : 'trend-up'" />
          {{ liability.pnl }}
        </div>
        <div v-else>
          {{ liability.pnl }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import {
  split, capitalize, find, includes,
} from 'lodash';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { useQueryParameters } from '@/composables';
import Icon from '@/components/common/Icon';
import { getQueryModel } from './liability-table-helper';

const queryModel = getQueryModel();

export default {
  components: {
    Icon,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { state, setParameters } = useQueryParameters(queryModel);
    const dollarValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    const numberFormat = new Intl.NumberFormat();
    const eventName = computed(() => {
      const eventNameSplit = split(props.event?.eventName, ' v ');
      return props.event?.isUsaView
        ? `${eventNameSplit[1]} @ ${eventNameSplit[0]}`
        : `${eventNameSplit[0]} - ${eventNameSplit[1]}`;
    });

    const matchState = computed(() => {
      if (props.event?.matchState === 'FINISHED' || props.event?.matchState === 'CANCELED') return capitalize(props.event?.matchState?.toLowerCase() || '');
      if (props.event?.matchState === 'LIVE') return 'Live';
      const time = props.event?.startsAt;
      const formatDate = format(zonedTimeToUtc(time, 'UTC'), 'd MMM');
      const formatTime = format(zonedTimeToUtc(time, 'UTC'), 'H:mm');
      return `${formatDate} (${formatTime})`;
    });

    const createLiabilities = (obj) => {
      if (!obj) {
        return {
          betsCount: '-',
          volume: '-',
          pnl: '-',
        };
      }
      return {
        betsCount: obj.betsCount,
        volume: dollarValue.format(obj.volume / 100),
        pnl: `${obj.pnl > 0 ? '+' : ''}${numberFormat.format(obj.pnl / 100)}`,
      };
    };

    const eventLiabilities = computed(() => {
      const liabilityObj = props.event?.eventLiabilities?.nodes?.[0];
      return createLiabilities(liabilityObj);
    });

    const moneyLineLiabilities = computed(() => {
      const foundMarket = find(props.event?.marketLiabilities?.nodes,
        (market) => market.marketCode === 'RESULT' && market.marketType?.params?.SELECTIONS === 2);
      return createLiabilities(foundMarket);
    });

    const spreadLiabilities = computed(() => {
      const foundMarket = find(props.event?.lineMarketLiabilities?.nodes,
        (market) => includes(market.marketCode, 'HANDICAP') && market.marketTypeWithoutLine?.params?.SELECTIONS === 2);
      return createLiabilities(foundMarket);
    });

    const totalLiabilities = computed(() => {
      const foundMarket = find(props.event?.lineMarketLiabilities?.nodes,
        (market) => includes(market.marketCode, 'OVER_UNDER') && market.marketTypeWithoutLine?.params?.SELECTIONS === 2);
      return createLiabilities(foundMarket);
    });

    const liabilities = computed(() => [eventLiabilities.value, moneyLineLiabilities.value, spreadLiabilities.value, totalLiabilities.value]);

    const loadEvent = () => {
      setParameters({
        ...state.value,
        eventId: props.event?.eventId,
      });
    };

    return {
      eventName,
      matchState,
      eventLiabilities,
      liabilities,
      loadEvent,
    };
  },
};
</script>

<style lang="scss">
.liability-table-row {
  width: 100%;
  height: 44px;
  display: flex;
  border-bottom: 1px solid #F0F0F0;
  min-width: fit-content;
  cursor: pointer;

  &:last-child {
    border-bottom: 0;
  }

  &:nth-child(even) {
    background-color: #FAFAFA;
  }

  .liability-table-row-column {
    height: 100%;
    padding: 0 8px;
    color: #191414;
    display: flex;
    width: 20%;
    min-width: 20%;
    align-items: center;
    border-right: 1px solid #F0F0F0;

    &:last-child {
      border-right: 0;
    }

    &.label-type {
      display: flex;
      flex-direction: column;
      align-items: unset;
      justify-content: center;
      border-right: 0;

      .column-label {
        max-width: 95%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &.info {
          font-size: 12px;
          color: #6D6D6D;

          &.live {
            span {
              color: #00BC57;
            }
          }
        }
      }
    }

    .column-liability {
      height: 100%;
      width: 33.33%;
      padding: 0 8px;
      display: flex;
      align-items: center;

      .column-liability-pnl {
        border: 1px solid #00BC57;
        background-color: #E5F8EE;
        color: #004B23;
        height: 23px;
        padding: 0 8px;
        gap: 4px;
        border-radius: 12px;
        border: 1px 0px 0px 0px;
        display: flex;
        align-items: center;

        .icon {
          width: 16px;
          height: 16px;

          svg {
            path {
              fill: #004B23;
            }
          }
        }

        &.down {
          background-color: #FFEEEE;
          border-color: #FF2E2D;
          color: #661212;
          .icon {
            svg {
              path {
                fill: #661212;
              }
            }
          }
        }
      }
    }
  }
}

</style>
