<template>
  <div class="liability-market-selection">
    <div class="liability-market-selection-column large">
      <div
        class="liability-market-selection-name"
        :title="!remapSelectionCode.twoPartLabel
          ? remapSelectionCode
          : `${remapSelectionCode.leftSide}/${remapSelectionCode.rightSide}`"
      >
        <span v-if="!remapSelectionCode.twoPartLabel">{{ remapSelectionCode }}</span>
        <div
          v-else
          class="two-part-selection-code"
        >
          <span class="left-side">{{ remapSelectionCode.leftSide }}</span>
          <span class="divider">/</span>
          <span class="right-side">{{ remapSelectionCode.rightSide }}</span>
        </div>
      </div>
    </div>
    <div class="liability-market-selection-column small">
      {{ selectionLiabilities.betsCount }}
    </div>
    <div class="liability-market-selection-column">
      {{ selectionLiabilities.volume }}
    </div>
    <div class="liability-market-selection-column">
      <div
        :class="['selection-liability-pnl', {'down': parseFloat(selectionLiabilities.pnl) < 0}]"
        v-if="selectionLiabilities.pnl !== '-' && selectionLiabilities.pnl !== '+0' && selectionLiabilities.pnl !== '-0'"
      >
        <Icon :name="parseFloat(selectionLiabilities.pnl) < 0 ? 'trend-down' : 'trend-up'" />
        {{ selectionLiabilities.pnl }}
      </div>
      <div v-else>
        {{ selectionLiabilities.pnl }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import {
  includes, toLower, replace, split,
} from 'lodash';
import Icon from '@/components/common/Icon';
import MarketMapper from '@/services/helpers/market-mapper';

export default {
  components: {
    Icon,
  },
  props: {
    market: {
      type: Object,
      required: true,
    },
    selection: {
      type: Object,
      required: true,
    },
    sportLabel: {
      type: String,
      required: true,
    },
    homeTeam: {
      type: Object,
      required: true,
    },
    awayTeam: {
      type: Object,
      required: true,
    },
    isUsaView: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const setTeamNames = (selectionLabel) => {
      let replacedLabel = toLower(selectionLabel);
      if (includes(replacedLabel, 'home') && replacedLabel !== 'home run') {
        replacedLabel = replace(replacedLabel, /home/g, props.homeTeam?.name);
      }
      if (includes(replacedLabel, 'away')) {
        replacedLabel = replace(replacedLabel, /away/g, props.awayTeam?.name);
      }
      if (includes(replacedLabel, '/')) {
        const labelSplited = split(replacedLabel, '/');
        replacedLabel = {
          twoPartLabel: true,
          leftSide: labelSplited[0],
          rightSide: labelSplited[1],
        };
      }
      return replacedLabel;
    };

    const remapSelectionCode = computed(() => {
      const selectionNameLabel = MarketMapper.selectionName(props.selection, { sportLabel: props.sportLabel }, props.isUsaView);
      return setTeamNames(selectionNameLabel);
    });

    const dollarValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    const numberFormat = new Intl.NumberFormat();

    const createLiabilities = (obj) => {
      if (!obj) {
        return {
          betsCount: '-',
          volume: '-',
          pnl: '-',
        };
      }
      return {
        betsCount: obj.betsCount,
        volume: dollarValue.format(obj.volume / 100),
        pnl: `${obj.pnl > 0 ? '+' : ''}${numberFormat.format(obj.pnl / 100)}`,
      };
    };

    const selectionLiabilities = computed(() => createLiabilities(props.selection));

    return {
      remapSelectionCode,
      selectionLiabilities,
    };
  },
};
</script>

<style lang="scss">
.liability-market-selection {
  width: 100%;
  height: 32px;
  display: flex;
  border-bottom: 1px solid #F0F0F0;

  .liability-market-selection-column {
    width: 27%;
    min-width: 27%;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 8px;

    &.large {
      width: 28%;
      min-width: 28%;
    }
    &.small {
      width: 18%;
      min-width: 18%;
    }

    .liability-market-selection-name {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;

      span {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
      }
    }

    .selection-liability-pnl {
      border: 1px solid #00BC57;
      background-color: #E5F8EE;
      color: #004B23;
      height: 23px;
      padding: 0 8px;
      gap: 4px;
      border-radius: 12px;
      border: 1px 0px 0px 0px;
      display: flex;
      align-items: center;

      .icon {
        width: 16px;
        height: 16px;

        svg {
          path {
            fill: #004B23;
          }
        }
      }

      &.down {
        background-color: #FFEEEE;
        border-color: #FF2E2D;
        color: #661212;
        .icon {
          svg {
            path {
              fill: #661212;
            }
          }
        }
      }
    }
  }
}
</style>
