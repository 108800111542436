<template>
  <div class="liability-wrapper">
    <div class="liability-table-wrapper">
      <div class="liability-header">
        <div class="liability-header-label">
          Liability monitor
        </div>
        <div class="liability-header-search">
          <TextInput
            :model-value="searchValue"
            :placeholder="'Search events'"
            :icon="'search'"
            @update:modelValue="onSearch"
          />
        </div>
      </div>
      <div class="liability-filters">
        <!-- sport filter -->
        <div
          class="liability-filter-wrapper sport"
          @click="() => { sportFilterToggled = !sportFilterToggled }"
          ref="sportDropdownRef"
        >
          <div class="selected-filter-value">
            <span>{{ selectedSport?.sportName }}</span>
            <Icon
              class="filter-icon"
              :name="'chevron-selector'"
            />
          </div>
          <div
            class="liability-filter-dropdown"
            v-if="sportFilterToggled"
          >
            <div
              class="liability-filter-option"
              v-for="sport in sports"
              :key="sport.sportId"
              @click="selectSport(sport)"
            >
              <RadioInput
                :checked="sport.sportId === selectedSportId"
              />
              {{ sport.sportName }}
            </div>
          </div>
        </div>
        <!-- competition filter -->
        <div
          class="liability-filter-wrapper competition"
          @click="() => { competitionFilterToggled = !competitionFilterToggled }"
          ref="competitionDropdownRef"
        >
          <div class="selected-filter-value">
            <span>{{ selectedCompetitionsLabel }}</span>
            <Icon
              class="filter-icon"
              :name="'chevron-selector'"
            />
          </div>
          <div
            class="liability-filter-dropdown"
            v-if="competitionFilterToggled"
          >
            <div
              class="liability-filter-option"
              v-for="competition in competitions"
              :key="competition.competitionId"
              @click="toggleCompetition(competition)"
            >
              <Checkbox
                :model-value="isCompetitionSelected(competition.competitionId)"
                @update:modelValue="toggleCompetition(competition)"
              />
              <span>{{ competition.competitionName }}</span>
            </div>
          </div>
        </div>
        <!-- date filter -->
        <div class="liability-date-filter">
          <DatePicker
            :model-value="dateValue"
            @update:model-value="(updatedValue) => updateDateFilter(updatedValue)"
            placeholder="Date"
            range
          />
        </div>
        <!-- state filter -->
        <div
          class="liability-filter-wrapper state"
          @click="() => { stateFilterToggled = !stateFilterToggled }"
          ref="stateDropdownRef"
        >
          <div class="selected-filter-value">
            <span>{{ selectedStateLabel }}</span>
            <Icon
              class="filter-icon"
              :name="'chevron-selector'"
            />
          </div>
          <div
            class="liability-filter-dropdown"
            v-if="stateFilterToggled"
          >
            <div
              class="liability-filter-option"
              @click="toggleState('LIVE')"
            >
              <Checkbox
                :model-value="isStateSelected('LIVE')"
                @update:modelValue="toggleState('LIVE')"
              />
              <span>Live</span>
            </div>
            <div
              class="liability-filter-option"
              @click="toggleState('PREMATCH')"
            >
              <Checkbox
                :model-value="isStateSelected('PREMATCH')"
                @update:modelValue="toggleState('PREMATCH')"
              />
              <span>Prematch</span>
            </div>
          </div>
        </div>
      </div>
      <div class="liability-body-wrapper">
        <LiabilityEventsTable
          v-if="liabilityEvents?.length && !liabilityEventsLoading"
          :events="liabilityEvents"
          :loading="liabilityEventsLoading"
          :table-columns="tableColumns"
        />
        <div
          class="empty-state-wrapper"
          v-if="!liabilityEventsLoading && !liabilityEvents?.length && selectedSport"
        >
          <EmptyState
            class="empty-state"
            :message="'No events at the moment'"
          />
        </div>
        <div
          class="loading-spinner-wrapper"
          v-if="liabilityEventsLoading"
        >
          <Spinner
            class="table-spinner"
          />
        </div>
      </div>
      <LiabilityFooter />
    </div>
    <LiabilityEvent
      :event-id="selectedEventId"
      v-if="selectedEventId"
    />
  </div>
</template>

<script>
import { ref, computed, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { onClickOutside } from '@vueuse/core';
import {
  debounce, find, includes, filter,
  cloneDeep, capitalize,
} from 'lodash';
import { useQueryParameters, watchQueryParameters } from '@/composables';
import TextInput from '@/components/common/TextInput';
import Icon from '@/components/common/Icon';
import RadioInput from '@/components/common/RadioInput';
import Checkbox from '@/components/common/Checkbox';
import EmptyState from '@/components/common/EmptyState';
import Spinner from '@/components/common/Spinner';
import DatePicker from '@/components/common/DatePicker';
import LiabilityEventsTable from './LiabilityEventsTable';
import LiabilityFooter from './LiabilityFooter';
import { generateEventColumns, getQueryModel } from './liability-table-helper';
import LiabilityEvent from './LiabilityEvent';

const queryModel = getQueryModel();

export default {
  components: {
    TextInput,
    Icon,
    RadioInput,
    Checkbox,
    LiabilityEventsTable,
    EmptyState,
    Spinner,
    DatePicker,
    LiabilityFooter,
    LiabilityEvent,
  },
  setup() {
    const store = useStore();

    const sportFilterToggled = ref(false);
    const competitionFilterToggled = ref(false);
    const stateFilterToggled = ref(false);
    const tableColumns = ref(generateEventColumns());

    const { state, parameters, setParameters } = useQueryParameters(queryModel);
    const sports = computed(() => store.getters['betTicker/liabilitySports']);
    const competitions = computed(() => store.getters['betTicker/liabilityCompetitions']);
    const liabilityEvents = computed(() => store.getters['betTicker/liabilityEvents']);
    const liabilityEventsLoading = computed(() => store.getters['betTicker/liabilityEventsLoading']);
    const selectedSportId = computed(() => state.value.sport);
    const selectedCompetitionsIds = computed(() => state.value.competition);
    const selectedStates = computed(() => state.value.state);
    const dateValue = computed(() => (state.value.from ? [state.value.from, state.value.to] : ''));
    const selectedSport = computed(() => find(sports.value, (sport) => sport.sportId === selectedSportId.value) || sports.value[0]);
    const searchValue = computed(() => state.value?.search || '');

    const selectedCompetitionsLabel = computed(() => {
      const competitionIds = selectedCompetitionsIds.value;
      if (competitionIds.length > 1) return `Competitions: +${competitionIds.length}`;
      if (competitionIds.length === 1) return `Competition: ${find(competitions.value, (competition) => competition.competitionId === competitionIds[0])?.competitionName || ''}`;
      return 'Competition';
    });
    const selectedStateLabel = computed(() => {
      const states = selectedStates.value;
      if (states.length > 1) return `State: +${states.length}`;
      if (states.length === 1) return `State: ${capitalize(states[0])}`;
      return 'State';
    });

    const selectSport = (sport) => {
      setParameters({
        ...parameters.value,
        sport: sport.sportId,
        competition: [],
        page: 1,
      });
    };
    const toggleCompetition = (competition) => {
      let competitionIds = cloneDeep(state.value.competition);
      if (includes(competitionIds, competition.competitionId)) {
        competitionIds = filter(competitionIds, (id) => id !== competition.competitionId);
      } else {
        competitionIds.push(competition.competitionId);
      }
      setParameters({
        ...parameters.value,
        competition: competitionIds,
        page: 1,
      });
    };
    const toggleState = (stateId) => {
      let states = cloneDeep(state.value.state);
      if (includes(states, stateId)) {
        states = filter(states, (id) => id !== stateId);
      } else {
        states.push(stateId);
      }
      setParameters({
        ...parameters.value,
        state: states,
        page: 1,
      });
    };
    const updateDateFilter = (newValue) => {
      setParameters({
        ...parameters.value,
        from: newValue?.[0] ? newValue[0] : '',
        to: newValue?.[1] ? newValue[1] : '',
        page: 1,
      });
    };

    const isCompetitionSelected = (competitionId) => {
      const competitionIds = selectedCompetitionsIds.value;
      return includes(competitionIds, competitionId);
    };
    const isStateSelected = (stateId) => {
      const states = selectedStates.value;
      return includes(states, stateId);
    };
    const onSearch = debounce((value) => {
      setParameters({
        ...parameters.value,
        search: value,
        page: 1,
      });
    }, 500);

    const selectedEventId = computed(() => state.value?.eventId || '');

    const sportDropdownRef = ref('');
    onClickOutside(sportDropdownRef, () => {
      sportFilterToggled.value = false;
    });

    const competitionDropdownRef = ref('');
    onClickOutside(competitionDropdownRef, () => {
      competitionFilterToggled.value = false;
    });

    const stateDropdownRef = ref('');
    onClickOutside(stateDropdownRef, () => {
      stateFilterToggled.value = false;
    });

    watchQueryParameters(queryModel, {
      onDeclare(id) {
        if (id === 'sport') {
          setParameters({
            ...parameters.value,
            sport: '841e188b-0dcf-4f5f-974f-aa52c8cec95b',
            page: 1,
          });
        }
      },
      onInit() {
        store.dispatch('betTicker/loadLiabilityFiltersMeta', state.value);
      },
      onChange(newParameters, oldParameters) {
        const hasSportChanged = newParameters.sport !== oldParameters.sport;
        const hasDateChanged = newParameters.from !== oldParameters.from || newParameters.to !== oldParameters.to;
        if (hasSportChanged || hasDateChanged) {
          store.dispatch('betTicker/loadLiabilityFiltersMeta', {
            ...state.value,
            competitionsOnly: true,
          });
          return;
        }
        if (newParameters?.eventId !== oldParameters?.eventId) return;
        store.dispatch('betTicker/loadLiabilityEvents', state.value);
      },
    });

    onUnmounted(() => {
      store.dispatch('betTicker/clearLiabilityEvents');
    });

    return {
      state,
      parameters,
      searchValue,
      onSearch,
      sportFilterToggled,
      sports,
      competitions,
      competitionFilterToggled,
      stateFilterToggled,
      selectedSportId,
      selectSport,
      selectedSport,
      sportDropdownRef,
      competitionDropdownRef,
      stateDropdownRef,
      selectedCompetitionsLabel,
      selectedStateLabel,
      toggleCompetition,
      toggleState,
      isCompetitionSelected,
      isStateSelected,
      liabilityEvents,
      liabilityEventsLoading,
      dateValue,
      updateDateFilter,
      tableColumns,
      selectedEventId,
    };
  },
};
</script>

<style lang="scss">
  .liability-wrapper {
    width: 100%;
    height: calc(100% - 69px);
    display: flex;
    justify-content: center;
    position: relative;

    .liability-table-wrapper {
      width: 100%;
      height: 100%;
      padding: 0 16px;
      box-sizing: border-box;

      .liability-header {
        height: 48px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .liability-header-label {
          height: 32px;
          line-height: 32px;
          color: #191414;
          font-weight: 600;
          font-size: 20px;
          font-family: 'Poppins';
        }

        .liability-header-search {
          height: 32px;
          width: 240px;

          .text-field {
            height: 32px;

            .text-field-clear {
              width: 32px;
              height: 32px;
            }
          }
        }
      }

      .liability-filters {
        height: 56px;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 8px;

        .liability-date-filter {
          width: 187px;
          height: 32px;
        }

        .liability-filter-wrapper {
          width: 167px;
          height: 32px;
          border-radius: 4px;
          border: 1px solid #F0F0F0;
          cursor: pointer;
          position: relative;

          .selected-filter-value {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 8px;

            span {
              max-width: 85%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .icon {
              width: 16px;
              height: 16px;
            }
          }

          .liability-filter-dropdown {
            width: 180px;
            min-height: 32px;
            position: absolute;
            top: 33px;
            left: 0;
            border-radius: 4px;
            background-color: #fff;
            border: 1px solid #F0F0F0;
            box-shadow: 0px 2px 4px 0px #19141414;
            z-index: 999;

            .liability-filter-option {
              height: 32px;
              width: 100%;
              cursor: pointer;
              padding:  0 8px;
              display: flex;
              align-items: center;

              &:hover {
                background-color: rgba(0,0,0, 0.03);
              }

              .radio-input, .checkbox {
                margin-right: 4px;
              }

              span {
                max-width: 90%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }

        }
      }

      .loading-spinner-wrapper {
        height: 60%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .liability-body-wrapper {
        height: calc(100% - 104px);
      }
    }

    .empty-state-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 400px;
      width: 100%;

      .empty-state {
        width: 400px;
      }
    }
  }
</style>
