import { includes } from 'lodash';

export const generateEventColumns = () => ([
  {
    id: 'eventName',
    label: 'EVENTS',
    type: 'label-type',
    position: 0,
    active: true,
  },
  {
    id: 'eventLiabilities',
    label: '',
    type: 'liability-type',
    position: 1,
    active: true,
  },
  {
    id: 'RESULT',
    label: 'MONEYLINE',
    type: 'liability-type',
    position: 2,
    active: true,
  },
  {
    id: 'HANDICAP',
    label: 'SPREAD',
    type: 'liability-type',
    position: 3,
    active: true,
  },
  {
    id: 'OVER_UNDER',
    label: 'TOTAL',
    type: 'liability-type',
    position: 4,
    active: true,
  },
]);

export const getQueryModel = () => ({
  sport: {
    type: String,
    required: true,
  },
  competition: {
    type: Array,
    default: () => [],
  },
  from: {
    type: String,
    default: '',
  },
  to: {
    type: String,
    default: '',
  },
  state: {
    type: Array,
    default: () => [],
  },
  search: {
    type: String,
    default: '',
  },
  eventId: {
    type: String,
    default: '',
  },
  page: {
    type: Number,
    default: 1,
    validator(value) {
      return value > 0;
    },
  },
  limit: {
    type: Number,
    default: 50,
    validator(value) {
      return includes([10, 20, 50, 100], value);
    },
  },
});
