<template>
  <div class="liability-table">
    <div class="liability-table-header">
      <div
        :class="['liability-table-header-column', column.type]"
        v-for="column in tableColumns"
        :key="column.id"
      >
        <div class="column-top">
          {{ column.label }}
        </div>
        <div class="column-bottom">
          <div
            class="column-label"
            v-if="column.type === 'label-type'"
          >
            Event name
          </div>
          <div
            v-else
            class="column-liability-wrapper"
          >
            <div class="column-liability-column">
              Bets
            </div>
            <div class="column-liability-column">
              Turnover
            </div>
            <div class="column-liability-column">
              P/L
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="liability-table-body">
      <LiabilityEventsTableRow
        v-for="event in events"
        :key="event.eventId"
        :event="event"
        :table-columns="tableColumns"
      />
    </div>
  </div>
</template>

<script>
import LiabilityEventsTableRow from './LiabilityEventsTableRow';

export default {
  components: {
    LiabilityEventsTableRow,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return {
    };
  },
};
</script>

<style lang="scss">
.liability-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .liability-table-header {
    width: 100%;
    height: 64px;
    background-color: #FAFAFA;
    border-radius: 4px;
    display: flex;
    min-width: fit-content;
    font-family: Rubik;

    .liability-table-header-column {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 8px;
      color: #6D6D6D;
      min-width: 20%;

      &.liability-type {
        min-width: 20%;
      }

      &.label_type {
        min-width: 20%;
      }

      .column-top {
        height: 32px;
        display: flex;
        align-items: center;
        color: #A9A9A9;

      }

      .column-bottom {
        height: 32px;
        display: flex;
        align-items: center;
        width: 100%;

        .column-label {
          width: 100%;
          height: 100%;
          line-height: 32px;
          padding: 0 8px;
        }

        .column-liability-wrapper {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;

          .column-liability-column {
            height: 100%;
            width: 33.33%;
            padding: 0 8px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .liability-table-body {
    margin-bottom: 76px;
    height: calc(100% - 140px);
    overflow-y: auto;
  }
}

</style>
